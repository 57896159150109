import { lazy, Suspense, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import hotjar from './helpers/Hotjar';
import * as Sentry from "@sentry/react";

import './App.css';
import Loader from './components/Loader';
import Intersection from './Intersection';
import Mixpanel from './helpers/mixpanel';

function App() {
    useEffect(() => {
        // initialize hotjar tracking
        const hotjarAppId = process.env.REACT_APP_HOTJAR_APP_ID;
        const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
        if (hotjarAppId && hotjarVersion) {
            hotjar.initialize(hotjarAppId, hotjarVersion);
        }

        Mixpanel.init();

        // initialize sentry
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration(),
            ],
            environment: process.env.REACT_APP_ENVIRONMENT,
            // Performance Monitoring
            tracesSampleRate: 1.0,
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
          });

        Sentry.setTag('application', 'visual-report');

        const handlePostMessage = (event: MessageEvent) => {
            if (
                event.origin !== process.env.REACT_APP_URL_MAP_FRONTEND ||
                !event.source
            )
                return;
            if (event.data.action === 'openTab') {
                event.source.postMessage(
                    {
                        action: 'openTab',
                        url: window.location.href.replace('/frame', ''),
                    },
                    process.env
                        .REACT_APP_URL_MAP_FRONTEND as WindowPostMessageOptions,
                );
            }
        };
        // Initial post message event listener
        window.addEventListener('message', handlePostMessage);
        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, []);

    const PageUnavailable = lazy(() =>
        import('./pages/default/PageUnavailable'),
    );
    return (
        <div className="App fancy-scroll">
            <Router>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        <Route path={'/selfstorage/'}>
                            <Intersection />
                        </Route>
                        <Route component={() => <PageUnavailable />} />
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
