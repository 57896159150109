import { useState, useEffect } from 'react';
import { OmniWebService } from '../types';
import hotjar from '../helpers/Hotjar';
import { getUserProduct, hasValidSubscription } from '../helpers/subscription';
import Mixpanel from '../helpers/mixpanel';

const useCurrentSession = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | any>(null);
    const [sessionInfo, setSessionInfo] = useState<null | OmniWebService.SessionInfo>(null);
    const [user, setUser] = useState<null | OmniWebService.User>(null);

    useEffect(() => {
        const getCurrentUser = async () => {
            setIsLoading(true);
            // fetch me
            try {
                const sessionInfoResponse = await fetch(
                    `${process.env.REACT_APP_URL_BACKEND_ACCOUNTS}/session-info`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    },
                );
                if (!sessionInfoResponse.ok) {
                    throw new Error('Problem fetching session info');
                }
                const sessionInfo: OmniWebService.SessionInfo = await sessionInfoResponse.json();

                if (!sessionInfo.user) {
                    throw new Error('No user found');
                }

                // track the user through Mixpanel
                Mixpanel.identifyAndSetUser(
                    `${sessionInfo.user.id}`,
                    `${sessionInfo.user.firstName} ${sessionInfo.user.lastName}`,
                    getUserProduct(sessionInfo),
                    sessionInfo.user.email);

                setSessionInfo(sessionInfo);
                setUser(sessionInfo.user);

                // At the moment visual reports are only available for tractiq and selfstorage
                if (hasValidSubscription(sessionInfo)) {
                    hotjar.identifyUser(sessionInfo.user.id, 'Professional plan');

                    hotjar.startRecording();
                }
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        getCurrentUser();
    }, []);

    return { isLoading, error, sessionInfo, user };
};

export default useCurrentSession;
