import { OmniWebService } from '../types';


export namespace SubscriptionProducts {
    export type SubscriptionProductType = SubscriptionProducts.ApplicationAccess | SubscriptionProducts.DataPackages;
    
    export enum ApplicationAccess {
        TRACTIQ_SELF_STORAGE = 'ACCESS__TRACTIQ_SELFSTORAGE',
        TRACTIQ_SELF_STORAGE_STARTER_TIER = 'ACCESS__TRACTIQ_SELFSTORAGE_STARTER_TIER',
    }

    export enum ApplicationAccessConfigurationOptions {
        ENABLE_RAW_DATA_EXPORT = 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_RAW_DATA_EXPORT',
        ENABLE_SITE_ANALYSIS_API = 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_SITE_ANALYSIS_API',
        ENABLE_OCCUPANCY_PAGE = 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_OCCUPANCY_PAGE',
        USES_BETA_MAPS_APP = 'ACCESS__TRACTIQ_SELFSTORAGE__USES_BETA_MAPS_APP',
    }

    export enum DataPackages {
        TRACTIQ_SELF_STORAGE_BASE = 'DATA_PACKAGE__TRACTIQ_SELFSTORAGE_BASE',
        PROPRIETARY_SINGLE_LOCATION_REPORT = 'DATA_PACKAGE__PROPRIETARY_SINGLE_LOCATION_REPORT',
    }

    export enum ProductTypes {
        PROFESSIONAL_PRODUCT = 'PRO',
        STARTER_PRODUCT = 'STARTER_PRODUCT',
        OTHER = '/',
    }
}


export const hasValidSubscription = (
    sessionInfo: OmniWebService.SessionInfo,
) => {
    return isPro(sessionInfo) || isStarter(sessionInfo);
};

export const isPro = (sessionInfo: OmniWebService.SessionInfo) => {
    return activeSubscriptionsContainProduct(
        sessionInfo.activeSubscriptions,
        SubscriptionProducts.ApplicationAccess.TRACTIQ_SELF_STORAGE,
    );
};

export const isStarter = (sessionInfo: OmniWebService.SessionInfo) => {
    return (
        !isPro(sessionInfo) &&
        activeSubscriptionsContainProduct(
            sessionInfo.activeSubscriptions,
            SubscriptionProducts.ApplicationAccess
                .TRACTIQ_SELF_STORAGE_STARTER_TIER,
        )
    );
};

export const getUserProduct = (sessionInfo: OmniWebService.SessionInfo): string => {
    return isPro(sessionInfo) ? SubscriptionProducts.ProductTypes.PROFESSIONAL_PRODUCT :
        isStarter(sessionInfo) ? SubscriptionProducts.ProductTypes.STARTER_PRODUCT : SubscriptionProducts.ProductTypes.OTHER;
};

export const activeSubscriptionsContainProduct = (
    subscriptions: OmniWebService.Subscription[],
    product: SubscriptionProducts.ApplicationAccess,
) => {
    return subscriptions.some((subscription) => {
        return subscription.products
            .map((product) => product.productId)
            .includes(product);
    });
};

export const hasFeatureToggle = (
    sessionInfo: OmniWebService.SessionInfo,
    featureId: SubscriptionProducts.ApplicationAccessConfigurationOptions,
) => {
    return sessionInfo.activeSubscriptions.some((subscription) =>
        subscription.products.some((product) =>
            product.configuration.features?.includes(featureId),
        ),
    );
};
