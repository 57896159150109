import mixpanel from 'mixpanel-browser';

class Mixpanel {
    static isMixpanelEnabled() {
        return process.env.REACT_APP_MIXPANEL_TOKEN !== '';
    };

    static init() {
        // init mixpanel
        if (this.isMixpanelEnabled()) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
                debug: false,
                track_pageview: true,
                persistence: 'localStorage'
            });
        }
    }

    static identifyAndSetUser(userId: string, userName: string, userProduct: string, userEmail: string) {
        if (this.isMixpanelEnabled()) {
            mixpanel.identify(userId);
            mixpanel.people.set({
                '$name': userName,
                '$email': userEmail,
                '$product': userProduct,
            });
        }
    }

    static emitUserEvent(mixpanelEvent: string, eventProperties = {}) {
        if (this.isMixpanelEnabled()) {
            mixpanel.track(mixpanelEvent, eventProperties);
        }
    }
}

export default Mixpanel;
