import { SubscriptionProducts } from '../helpers/subscription';
import { Report } from '../types';

export const REPORTS = {
    marketSummary: {
        label: 'Executive Summary',
        basePath: `/selfstorage/market-profile/:frame?`,
    } as Report,
    demography: {
        label: 'Demography',
        basePath: `/selfstorage/demography/:frame?`,
    } as Report,
    opportunity: {
        label: 'Opportunity',
        basePath: `/selfstorage/selfstorage-opportunity/:frame?`,
    } as Report,
    pricing: {
        label: 'Rate Trends',
        basePath: `/selfstorage/selfstorage-price/:frame?`,
    } as Report,
    rentalComps: {
        label: 'Rental Comps',
        basePath: `/selfstorage/selfstorage-comps/:frame?`,
    } as Report,
    selfStoragePointInfo: {
        label: 'Point Info',
        basePath: `/selfstorage/selfstorage-point-info/:frame?`,
    } as Report,
    selfStorageOccupancyReport: {
        label: 'Stabilized Occupancy',
        basePath: `/selfstorage/selfstorage-occupancy-report/:frame?`,
        accessControl: SubscriptionProducts.ApplicationAccessConfigurationOptions.ENABLE_OCCUPANCY_PAGE,
        beta: true,
    } as Report,
} as const;
